<template>
	<b-modal id="add-asset-issuance" size="xl" title="Add Asset Issuance" ref="modal" ok-title="Save" @ok="handleOk"
		@show="onReset" :cancel-disabled="disableConfirmButtons" :ok-disabled="disableConfirmButtons"
		:no-close-on-backdrop="true">
		<loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />

		<b-form @submit.stop.prevent="handleSubmit" novalidate>
			<b-container fluid>
				<div>
					<b-tabs>
						<b-tab title="Primary Information" active>
							<b-row class="my-2">
								<b-col sm="8">
									<b>PRIMARY INFORMATION</b>
								</b-col>
							</b-row>
							<b-row class="my-3">
								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Company" label-for="Company" description="The company who will issue the asset">
										<v-select name="Company" class="style-chooser" label="text" :options="companyOptions"
											:reduce="(company) => company.value" v-model="selCompany" v-validate="'selectRequired'">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a company
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('Company')" class="help-block">{{
											errors.first('Company')
										}}</span>
									</b-form-group>
								</b-col>

								<b-col lg="6" md="12" sm="12">
									<b-form-group label="User" label-for="User" description="The person whom you will issue the asset to">
										<v-select name="User" class="style-chooser" label="text" :options="userOptions"
											:reduce="(user) => user.value" v-model="selUser" v-validate="'selectRequired'">
											<template v-slot:no-options="{ search, searching }">
												<template v-if="searching">
													No results found for
													<em>
														<strong>{{ search }}</strong>
													</em>
												</template>
												<em :style="{ opacity: 0.5 }" v-else>
													Start typing to search for a user
												</em>
											</template>
										</v-select>
										<span v-show="errors.has('User')" class="help-block">{{
											errors.first('User')
										}}</span>
									</b-form-group>
								</b-col>

								<b-col lg="6" md="12" sm="12">
									<b-form-group label="Reason for Issuance" label-for="Reason for Issuance"
										description="Indicate your purpose for asset issuances here">
										<b-form-textarea name="Reason for Issuance" type="text" v-model="form.description"
											maxlength="200" :rows="3" v-validate="{
												required: true,
												regex: remarksRegex
											}" placeholder="Some description here" />
										<span v-show="errors.has('Reason for Issuance')" class="help-block">{{
											errors.first('Reason for Issuance')
										}}</span>
									</b-form-group>
								</b-col>
							</b-row>
						</b-tab>

						<b-tab title="Asset(s) To Issue">
							<b-row class="my-2">
								<b-col sm="8">
									<b>ASSET(S) TO ISSUE</b>
								</b-col>
							</b-row>
							<b-row class="my-3" v-show="selCompany.id !== null">
								<b-col lg="6" md="12" sm="12">
									<AssetIssuanceTable :selCompany="selCompany" />
								</b-col>
								<b-col lg="6" md="12" sm="12">
									<SelectedAssetIssuanceTable @onAddIssuanceAssets="addSelAssets" @onDeleteAssets="deleteAssets" />
								</b-col>
							</b-row>
						</b-tab>
					</b-tabs>
				</div>
			</b-container>
		</b-form>
	</b-modal>
</template>

<script>
// Components
import AssetIssuanceTable from './AssetIssuanceTable';
import SelectedAssetIssuanceTable from './SelectedAssetIssuanceTable.vue';

// Util
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { ValidationUtil } from '@/utils/validationUtil';
import { DateUtil } from '@/utils/dateutil';

// API
import assetIssuanceApi from '@/api/assetIssuanceApi';
import assetIssuanceDAO from '@/database/assetIssuances';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import _ from 'lodash';

export default {
	name: 'add-asset-issuance',
	components: {
		Loading,
		AssetIssuanceTable,
		SelectedAssetIssuanceTable,
	},
	props: {
		assetTypeOptions: {
			type: Array,
			required: true,
		},
		allCompaniesObj: {
			type: Object,
			required: true,
		},
		allUsersObj: {
			type: Object,
			required: true,
		},
		allAssetIssuancesObj: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			fields: [
				{
					key: 'asset_code',
					label: 'Asset Code',
					sortable: true,
				},
				{
					key: 'asset_name',
					label: 'Asset Name',
					sortable: false,
				},
				{
					key: 'action',
					label: 'Action',
				},
			],
			form: {
				issuanceId: '',
				company: '',
				companyId: '',
				firstName: '',
				lastName: '',
				userId: '',
				userCompanyId: '',
				userCompany: '',
				assetType: '',
				assetTypeId: '',
				assetCode: '',
				assetName: '',
				description: '',
				notes: '',
				status: '',
				dateIssued: null,
				issuedBy: '',
				dateCancelled: null,
				cancelledBy: '',
				dateReturned: null,
				receivedBy: '',
			},

			companyOptions: [],
			userOptions: [],

			selCompany: config.companyDefaultValue,
			selUserCompany: config.companyDefaultValue,
			selUser: config.userDefaultValue,
			selAssets: {},

			assetIssuanceStatus: config.assetIssuanceStatus,
			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			currUserId: this.$store.getters.loggedUser.id,

			// Check for loader
			isLoading: false,
		};
	},
	computed: {
		disableConfirmButtons() {
			return this.isLoading;
		},
		issuanceId() {
			return this.form.issuanceId;
		},
		remarksRegex() {
			return config.remarksRegex;
		}
	},
	watch: {
		selCompany: function (newVal) {
			if (newVal && newVal.id) {
				this.onChangeCompany();
			}
		},
		selUser: function (newVal) {
			if (newVal && newVal.id) {
				this.onChangeUser();
			}
		},
	},
	methods: {
		onChangeCompany() {
			let companyId = this.selCompany.id;
			this.form.company = this.selCompany.name;
			this.form.companyId = companyId;

			this.updateUserOptions(companyId);
		},
		updateUserOptions(companyId) {
			// filter user based on selected company
			this.userOptions = DropDownItemsUtil.retrieveActiveUsers(
				this.allUsersObj
			);
			this.userOptions = _.filter(this.userOptions, (o) => {
				return o.text === ' - Please select - ' || o.value.companyId === companyId;
			});
			this.selUser = config.userDefaultValue;
		},
		onChangeUser() {
			let userId = this.selUser.id;
			let userObj = this.allUsersObj[userId];
			if (!_.isEmpty(userObj)) {
				this.form.userId = userId;
				this.form.firstName = userObj.firstName;
				this.form.lastName = userObj.lastName;
				this.form.userCompanyId = userObj.companyId;
				this.form.userCompany = userObj.company;
			}
		},

		async handleOk(evt) {
			// Prevent modal from closing
			evt.preventDefault();

			this.cleanupFormFields();

			// show loading indicator
			this.isLoading = true;

			let isValid = await this.$validator.validateAll();
			if (!isValid) {
				this.$toaster.warning('Please address the field/s with invalid input.');
				// hide loading indicator
				this.isLoading = false;
				return;
			}

			if (_.isEmpty(this.selAssets)) {
				this.$toaster.warning('At least 1 asset must be selected for asset(s) to issue.');
				// hide loading indicator
				this.isLoading = false;
				return;
			} else {
				if (_.size(this.selAssets) > 5) {
					this.$toaster.warning('You can only issue maximum of 5 asset(s) per issuance.');
					// hide loading indicator
					this.isLoading = false;
					return;
				}

				let hasExisting = await this.hasExistingAssetIssuance();
				if (hasExisting) {
					this.$toaster.warning('There is already an ongoing asset issuance for the asset(s) selected.');
					// hide loading indicator
					this.isLoading = false;
					return;
				}
			}

			this.handleSubmit();
		},
		async hasExistingAssetIssuance() {
			let ongoingAssetIssuances = [];

			let assetCodes = _.map(this.selAssets, 'assetCode');
			if (!_.isEmpty(assetCodes)) {
				let assetIssuancesObj = await assetIssuanceDAO.getAssetIssuancesByAssetCodes(assetCodes);

				if (!_.isEmpty(assetIssuancesObj)) {
					ongoingAssetIssuances = _.filter(assetIssuancesObj, (o) => {
						return o.status === this.assetIssuanceStatus.ISSUED;
					});
				}
			}

			return !_.isEmpty(ongoingAssetIssuances);
		},
		cleanupFormFields() {
			this.form.description = ValidationUtil.removeExcessWhiteSpace(
				this.form.description
			);
		},
		generateAssetIssuances(form, assetsObj) {
			let assetIssuancesArr = [];

			let assetCodes = Object.keys(assetsObj);
			for (const assetCode of assetCodes) {
				let currTimestamp = DateUtil.getCurrentTimestamp();
				let assetIssuance = { ...form };

				// assign issuance id
				assetIssuance.issuanceId = 'AS' + currTimestamp;
				// default status upon creation
				assetIssuance.status = this.assetIssuanceStatus.ISSUED;

				// add asset details
				let assetObj = assetsObj[assetCode];

				if (!_.isEmpty(assetObj)) {
					assetIssuance.assetCode = assetObj.assetCode;
					assetIssuance.assetType = assetObj.assetType;
					assetIssuance.assetTypeId = assetObj.assetTypeId;

					if (assetObj.details && assetObj.details.name) {
						assetIssuance.assetName = assetObj.details.name;
					} else {
						// assign asset type as the default asset name
						assetIssuance.assetName = assetObj.assetType;
					}
				}

				// add timestamp
				assetIssuance.dateIssued = currTimestamp;
				assetIssuance.issuedBy = this.currUserId;

				// add to the asset issuances to save
				assetIssuancesArr.push(assetIssuance);
			}

			return assetIssuancesArr;
		},
		getParam() {
			let assetIssuancesArr = this.generateAssetIssuances(
				this.form,
				this.selAssets
			);

			return {
				currUserId: this.currUserId,
				assetIssuances: assetIssuancesArr,
			};
		},
		async handleSubmit() {
			// show loading indicator
			this.isLoading = true;

			try {
				let { data } = await assetIssuanceApi.saveAssetIssuances(
					this.getParam()
				);

				if (data.isSuccess) {
					this.$toaster.success(data.message);
					EventBus.$emit('onCloseAddAssetIssuance', data.assetIssuances);
					this.$refs.modal.hide();
				} else {
					this.$toaster.error(`Error saving Asset Issuance "${this.issuanceId}". Please try again.`);
				}

			} catch (_error) {
				this.$toaster.error(`Error saving Asset Issuance "${this.issuanceId}". Please try again.`);
			}

			// hide loading indicator
			this.isLoading = false;
		},

		addSelAssets(selAssetsArr) {
			selAssetsArr.forEach((asset) => {
				if (!this.selAssets[asset.assetCode]) {
					this.selAssets[asset.assetCode] = asset;
				}
			});
		},
		deleteAssets(assetCodesArr) {
			assetCodesArr.forEach((assetCode) => {
				if (this.selAssets[assetCode]) {
					delete this.selAssets[assetCode];
				}
			});
		},

		onReset() {
			/* Reset our form values */
			this.form = {
				company: '',
				companyId: '',
				firstName: '',
				lastName: '',
				userId: '',
				userCompanyId: '',
				userCompany: '',
				assetType: '',
				assetTypeId: '',
				assetCode: '',
				assetName: '',
				description: '',
				notes: '',
				status: '',
				dateIssued: null,
				issuedBy: '',
				dateCancelled: null,
				cancelledBy: '',
				dateReturned: null,
				receivedBy: '',
			};

			this.renderDropdownFieldOptions();

			// reset validation
			this.$validator.reset();
			this.errors.clear();
		},
		renderDropdownFieldOptions() {
			// render company options
			this.companyOptions = DropDownItemsUtil.retrieveActiveCompanies(this.allCompaniesObj);
			this.selCompany = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
			this.form.company = this.selCompany.name;
			this.form.companyId = this.selCompany.id;

			// render user options
			this.updateUserOptions(this.selCompany.id);

			// reset selected assets
			this.selAssets = {};
		},
	},
};
</script>